import React from 'react'
import './Projects.css'
import Cyprinciple from '../Cyprinciple/Cyprinciple';
import logonib from '../../Assets/LOGO-Nib.png'
const Projects= ()=> {
 
      return (
            <>
     <div className='proj contain'>
      <div className='projectleft'>
      <h1> SWIFT CSCF Compliance Assessment Project</h1>
      <img src={logonib} className='lognib'/>
      <p> We are engaged in Nib International Bank’s SWIFT CSCF security compliance Assessment. The SWIFT CSCF is a set of security controls that financial institutions and organizations connected to SWIFT are required to implement. The framework is designed to improve the security of financial transactions, reduce the risk of cyber-attacks, and enhance the integrity of the SWIFT network. 
            We are confident enough to assist the Bank to ensure that its SWIFT network is compliant with the CSCF V2024.  </p>
            </div>
     
      </div>
      <div className='gaping'>
      <Cyprinciple/>
      </div>
      </>
   );  
  
}

export default Projects